@import "mixins";

body {
  font-family: 'Open Sans', sans-serif;
  color: #444;
  overflow-x: hidden;
}

p, li {
  font-size: 24px;
  line-height: 32px;
  @include m {
    font-size: 22px;
    line-height: 30px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: 1px;
  @include m {
    font-size: 23px;
    line-height: 32px;
  }
}