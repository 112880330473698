// Includes
@import "reset";
@import "mixins";
@import "global";

@import "components/balloon";

@import "overrides";
@import "helpers";

.fk-button {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border-radius: 100px;
  background-color: #48B1BF;
  padding: 12px 24px;
  width: 100%;
  max-width: 345px;
  display: inline-block;
  text-decoration: none;

  &:focus,
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.fk-section {
}

.fk-section--top {
  padding: 29px 0;
  text-align: center;
  background: #35b5bc;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-top-left.svg') -45px 0 no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-top-right.svg') calc(100% + 45px) 0 no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
  @include m {
    padding: 20px 0;
  }
}

.fk-top-bar {
  text-align: center;
}

.fk-top-bar__title {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  @include m {
    font-size: 24px;
    line-height: 33px;
  }
}

.fk-section--hero {
}

.fk-hero {
  text-align: center;
  padding: 40px 0 106px;
  margin: 0 -30px;
  @include middle {
    margin: 0;
  }
  @include m {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 75px;
  }
}

.fk-hero__logo {
  margin-bottom: 40px;
  @include m {
    max-width: 280px;
    margin: 0 auto 30px;
  }
}

.fk-hero__usp {
  color: #48B1BF;
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
  @include m {
    font-size: 23px;
    line-height: 32px;
    padding: 0 20px;
  }
}

.fk-section--intro {
  background: #35b5bc;
  padding: 47px 0 24px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-intro-left.svg') -45px -20px no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-intro-right.svg') calc(100% + 45px) -1px no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
}

.fk-intro {
  color: #fff;
  position: relative;
  z-index: 1000;
}

.fk-intro__phone {
  position: relative;
  top: -90px;
  margin-bottom: -90px;
  @include m {
    position: relative;
    top: -100px;
    margin-bottom: -75px;
    text-align: center;
  }
}

.fk-intro__phone__seal {
  position: absolute;
  bottom: -8px;
  right: -6px;
  @include m {
    width: 100%;
    text-align: center;
    img {
      max-width: 140px;
    }
  }
}

.fk-intro__title {
  margin-bottom: 23px;
  // Wordbreak hack
  margin-right: -10px;
}

.fk-intro__paragraph {
  margin-bottom: 62px;
  @include m {
    margin-bottom: 25px;
  }
}

.fk-intro__store {
  display: flex;
  @include m {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fk-intro__store__banner {
  margin-right: 20px;
  @include m {
    margin: 5px 0;
  }
}

.fk-section--gallery {

}

.fk-gallery {
  padding: 60px 0;
  text-align: center;
  @include m {
    padding: 30px 0;
  }
}

.fk-gallery__pics {
  margin-bottom: 45px;
  @include middle {
    .col-6 {
      padding: 10px;
    }
  }
  @include m {
    max-width: 440px;
    margin: 0 auto 45px;
    .col-6 {
      padding: 10px;
    }
  }
}

.fk-gallery__about {
  margin-bottom: 40px;
  @include m {
    margin-bottom: 27px;
  }
}

.fk-gallery__cta {
}

.fk-section--contact {
}

.fk-contact {
  padding: 60px 0;
  @include m {
    max-width: 440px;
    margin: 0 auto;
    padding: 30px 0;
  }
}

.fk-contact__contacts {
  padding-left: 50px;
  margin-bottom: 34px;
  @include m {
    padding-left: 41px;
  }
  li {
    color: #343434;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 24px;
    position: relative;
    @include m {
      font-size: 22px;
      line-height: 37px;
    }
    &.-icon-phone {
      &::before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        background: url('../images/icon-phone.png') no-repeat;
        top: 12px;
        left: -41px;
      }
    }
    &.-icon-mail {
      &::before {
        content: '';
        width: 22px;
        height: 18px;
        position: absolute;
        background: url('../images/icon-mail.png') no-repeat;
        top: 12px;
        left: -41px;
      }
    }
    &.-icon-pin {
      &::before {
        content: '';
        width: 17px;
        height: 22px;
        position: absolute;
        background: url('../images/icon-pin.png') no-repeat;
        top: 12px;
        left: -38px;
      }
    }
  }
}

.fk-contact__social {
  display: flex;
  margin: 0 -14px;
  @include m {
    margin: 0 0 30px;
    display: flex;
    justify-content: space-around;
  }
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 14px;
    a {
      text-decoration: none;
    }
  }
}

.fk-section--testimonials {
}

.fk-testimonials {
  padding: 60px 0 112px;
  @include m {
    padding-bottom: 143px;
  }
}

.fk-testimonials__slide {
  display: flex;
  width: 100%;
  @include m {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.fk-testimonials__left {
  width: 100%;
  max-width: 160px;
  @include m {
    margin-bottom: 30px;
  }
}

.fk-testimonials__pic {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.fk-testimonials__right {
}

.fk-testimonials__text {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 12px;
  @include m {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
    text-align: center;
  }
}

.fk-testimonials__author {
  color: #898989;
  font-size: 18px;
  line-height: 24px;
}

.fk-section--download {
  background: #35b5bc;
  color: #FFFFFF;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-download-left.svg') -45px 0 no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-download-right.svg') calc(100% + 45px) 0 no-repeat;
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }
}

.fk-download {
  padding: 40px 0 50px;
  position: relative;
  z-index: 1000;

  @include m {
    max-width: 440px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

.fk-download__phone {
  @include m {
    position: relative;
    top: -80px;
    margin-bottom: -60px;
  }
}

.fk-download__title {
  margin-bottom: 30px;
  @include m {
    margin-bottom: 20px;
  }
}

.fk-download__list {
  margin-bottom: 30px;
  li {
    padding-left: 14px;
    position: relative;
    margin-bottom: 8px;
    @include m {
      margin-bottom: 13px;
    }
    &:before {
      content: '';
      left: 0;
      top: 16px;
      height: 4px;
      width: 4px;
      background: #FFFFFF;
      border-radius: 50%;
      position: absolute;
    }
  }
}

.fk-download__store {
  display: flex;
  @include m {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fk-download__store__banner {
  margin-right: 20px;
  @include m {
    margin: 5px 0;
  }
}

.fk-footer {
  padding: 42px;
  text-align: center;
  @include m {
    padding: 32px;
  }
}

.fk-footer__logo {
}