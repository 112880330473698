/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #444;
  overflow-x: hidden;
}

p, li {
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 767px) {
  p, li {
    font-size: 22px;
    line-height: 30px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  h2 {
    font-size: 23px;
    line-height: 32px;
  }
}

.fk-balloons img {
  position: absolute;
  z-index: 1000;
  animation-duration: 2s;
  animation-name: balloon;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.fk-balloons img:nth-of-type(1) {
  top: 319px;
  left: -38px;
  transform: rotate(10deg) scale(1.2);
  animation-name: balloon1;
}

.fk-balloons img:nth-of-type(2) {
  top: 66px;
  left: -3px;
  transform: rotate(1deg) scale(1.05);
  animation-name: balloon2;
}

.fk-balloons img:nth-of-type(3) {
  top: 163px;
  left: 58px;
  transform: rotate(30deg) scale(1.1);
  animation-name: balloon3;
}

.fk-balloons img:nth-of-type(4) {
  top: 23px;
  left: 200px;
  transform: rotate(2deg);
  animation-name: balloon4;
}

.fk-balloons img:nth-of-type(5) {
  top: 26px;
  right: 160px;
  transform: rotate(1deg);
  animation-name: balloon1;
}

.fk-balloons img:nth-of-type(6) {
  top: 46px;
  right: 106px;
  transform: rotate(3deg);
  animation-name: balloon2;
}

.fk-balloons img:nth-of-type(7) {
  top: 140px;
  right: -19px;
  transform: rotate(36deg) scale(1.3);
  animation-name: balloon3;
}

.fk-balloons img:nth-of-type(8) {
  top: 315px;
  right: 49px;
  transform: rotate(10deg) scale(1.6);
  animation-name: balloon4;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-balloons {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-balloons {
    display: none;
  }
}

@keyframes balloon1 {
  from {
    transform: scale(1) translateY(-5px);
  }
  to {
    transform: scale(1.1) translateY(3px);
  }
}

@keyframes balloon2 {
  from {
    transform: scale(1) translateX(-2px) rotate(0deg);
  }
  to {
    transform: scale(1.1) translateX(3px) rotate(5deg);
  }
}

@keyframes balloon3 {
  from {
    transform: scale(1) translateY(0px);
  }
  to {
    transform: scale(1.2) translateY(3px);
  }
}

@keyframes balloon4 {
  from {
    transform: scale(1) rotate(0deg) translateY(-6px);
  }
  to {
    transform: scale(1.1) rotate(-5deg) translateY(2px);
  }
}

.container {
  max-width: 966px;
}

.flickity-prev-next-button.next {
  right: -40px;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .flickity-prev-next-button.next {
    display: none;
  }
}

@media (max-width: 767px) {
  .flickity-prev-next-button.next {
    display: none;
  }
}

.flickity-prev-next-button.previous {
  left: -40px;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .flickity-prev-next-button.previous {
    display: none;
  }
}

@media (max-width: 767px) {
  .flickity-prev-next-button.previous {
    display: none;
  }
}

.flickity-page-dots {
  bottom: 60px;
}

.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
}

.flickity-page-dots .dot.is-selected {
  background: #48B1BF;
}

@media (max-width: 767px) {
  .flickity-page-dots {
    bottom: 80px;
  }
}

.h-bb-gray {
  border-bottom: solid 1px #ddd;
}

.fk-button {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border-radius: 100px;
  background-color: #48B1BF;
  padding: 12px 24px;
  width: 100%;
  max-width: 345px;
  display: inline-block;
  text-decoration: none;
}

.fk-button:focus, .fk-button:hover {
  color: #fff;
  text-decoration: none;
}

.fk-section--top {
  padding: 29px 0;
  text-align: center;
  background: #35b5bc;
  position: relative;
}

.fk-section--top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-top-left.svg") -45px 0 no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--top::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--top::before {
    display: none;
  }
}

.fk-section--top::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-top-right.svg") calc(100% + 45px) 0 no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--top::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--top::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--top {
    padding: 20px 0;
  }
}

.fk-top-bar {
  text-align: center;
}

.fk-top-bar__title {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
}

@media (max-width: 767px) {
  .fk-top-bar__title {
    font-size: 24px;
    line-height: 33px;
  }
}

.fk-hero {
  text-align: center;
  padding: 40px 0 106px;
  margin: 0 -30px;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-hero {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .fk-hero {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 75px;
  }
}

.fk-hero__logo {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .fk-hero__logo {
    max-width: 280px;
    margin: 0 auto 30px;
  }
}

.fk-hero__usp {
  color: #48B1BF;
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
}

@media (max-width: 767px) {
  .fk-hero__usp {
    font-size: 23px;
    line-height: 32px;
    padding: 0 20px;
  }
}

.fk-section--intro {
  background: #35b5bc;
  padding: 47px 0 24px;
  position: relative;
}

.fk-section--intro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-intro-left.svg") -45px -20px no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--intro::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--intro::before {
    display: none;
  }
}

.fk-section--intro::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-intro-right.svg") calc(100% + 45px) -1px no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--intro::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--intro::after {
    display: none;
  }
}

.fk-intro {
  color: #fff;
  position: relative;
  z-index: 1000;
}

.fk-intro__phone {
  position: relative;
  top: -90px;
  margin-bottom: -90px;
}

@media (max-width: 767px) {
  .fk-intro__phone {
    position: relative;
    top: -100px;
    margin-bottom: -75px;
    text-align: center;
  }
}

.fk-intro__phone__seal {
  position: absolute;
  bottom: -8px;
  right: -6px;
}

@media (max-width: 767px) {
  .fk-intro__phone__seal {
    width: 100%;
    text-align: center;
  }
  .fk-intro__phone__seal img {
    max-width: 140px;
  }
}

.fk-intro__title {
  margin-bottom: 23px;
  margin-right: -10px;
}

.fk-intro__paragraph {
  margin-bottom: 62px;
}

@media (max-width: 767px) {
  .fk-intro__paragraph {
    margin-bottom: 25px;
  }
}

.fk-intro__store {
  display: flex;
}

@media (max-width: 767px) {
  .fk-intro__store {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fk-intro__store__banner {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .fk-intro__store__banner {
    margin: 5px 0;
  }
}

.fk-gallery {
  padding: 60px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .fk-gallery {
    padding: 30px 0;
  }
}

.fk-gallery__pics {
  margin-bottom: 45px;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-gallery__pics .col-6 {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .fk-gallery__pics {
    max-width: 440px;
    margin: 0 auto 45px;
  }
  .fk-gallery__pics .col-6 {
    padding: 10px;
  }
}

.fk-gallery__about {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .fk-gallery__about {
    margin-bottom: 27px;
  }
}

.fk-contact {
  padding: 60px 0;
}

@media (max-width: 767px) {
  .fk-contact {
    max-width: 440px;
    margin: 0 auto;
    padding: 30px 0;
  }
}

.fk-contact__contacts {
  padding-left: 50px;
  margin-bottom: 34px;
}

@media (max-width: 767px) {
  .fk-contact__contacts {
    padding-left: 41px;
  }
}

.fk-contact__contacts li {
  color: #343434;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 24px;
  position: relative;
}

@media (max-width: 767px) {
  .fk-contact__contacts li {
    font-size: 22px;
    line-height: 37px;
  }
}

.fk-contact__contacts li.-icon-phone::before {
  content: '';
  width: 22px;
  height: 22px;
  position: absolute;
  background: url("../images/icon-phone.png") no-repeat;
  top: 12px;
  left: -41px;
}

.fk-contact__contacts li.-icon-mail::before {
  content: '';
  width: 22px;
  height: 18px;
  position: absolute;
  background: url("../images/icon-mail.png") no-repeat;
  top: 12px;
  left: -41px;
}

.fk-contact__contacts li.-icon-pin::before {
  content: '';
  width: 17px;
  height: 22px;
  position: absolute;
  background: url("../images/icon-pin.png") no-repeat;
  top: 12px;
  left: -38px;
}

.fk-contact__social {
  display: flex;
  margin: 0 -14px;
}

@media (max-width: 767px) {
  .fk-contact__social {
    margin: 0 0 30px;
    display: flex;
    justify-content: space-around;
  }
}

.fk-contact__social li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
}

.fk-contact__social li a {
  text-decoration: none;
}

.fk-testimonials {
  padding: 60px 0 112px;
}

@media (max-width: 767px) {
  .fk-testimonials {
    padding-bottom: 143px;
  }
}

.fk-testimonials__slide {
  display: flex;
  width: 100%;
}

@media (max-width: 767px) {
  .fk-testimonials__slide {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.fk-testimonials__left {
  width: 100%;
  max-width: 160px;
}

@media (max-width: 767px) {
  .fk-testimonials__left {
    margin-bottom: 30px;
  }
}

.fk-testimonials__pic {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.fk-testimonials__text {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  .fk-testimonials__text {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
    text-align: center;
  }
}

.fk-testimonials__author {
  color: #898989;
  font-size: 18px;
  line-height: 24px;
}

.fk-section--download {
  background: #35b5bc;
  color: #FFFFFF;
  position: relative;
}

.fk-section--download::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-download-left.svg") -45px 0 no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--download::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--download::before {
    display: none;
  }
}

.fk-section--download::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-download-right.svg") calc(100% + 45px) 0 no-repeat;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .fk-section--download::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .fk-section--download::after {
    display: none;
  }
}

.fk-download {
  padding: 40px 0 50px;
  position: relative;
  z-index: 1000;
}

@media (max-width: 767px) {
  .fk-download {
    max-width: 440px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .fk-download__phone {
    position: relative;
    top: -80px;
    margin-bottom: -60px;
  }
}

.fk-download__title {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .fk-download__title {
    margin-bottom: 20px;
  }
}

.fk-download__list {
  margin-bottom: 30px;
}

.fk-download__list li {
  padding-left: 14px;
  position: relative;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .fk-download__list li {
    margin-bottom: 13px;
  }
}

.fk-download__list li:before {
  content: '';
  left: 0;
  top: 16px;
  height: 4px;
  width: 4px;
  background: #FFFFFF;
  border-radius: 50%;
  position: absolute;
}

.fk-download__store {
  display: flex;
}

@media (max-width: 767px) {
  .fk-download__store {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fk-download__store__banner {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .fk-download__store__banner {
    margin: 5px 0;
  }
}

.fk-footer {
  padding: 42px;
  text-align: center;
}

@media (max-width: 767px) {
  .fk-footer {
    padding: 32px;
  }
}
