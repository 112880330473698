.fk-balloons {
    img {
      position: absolute;
      z-index: 1000;
      animation-duration: 2s;
      animation-name: balloon;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      &:nth-of-type(1) {
        top: 319px;
        left: -38px;
        transform: rotate(10deg) scale(1.2);
        animation-name: balloon1;
      }
      &:nth-of-type(2) {
        top: 66px;
        left: -3px;
        transform: rotate(1deg) scale(1.05);
        animation-name: balloon2;
      }
      &:nth-of-type(3) {
        top: 163px;
        left: 58px;
        transform: rotate(30deg) scale(1.1);
        animation-name: balloon3;
      }
      &:nth-of-type(4) {
        top: 23px;
        left: 200px;
        transform: rotate(2deg);
        animation-name: balloon4;
      }
      &:nth-of-type(5) {
        top: 26px;
        right: 160px;
        transform: rotate(1deg);
        animation-name: balloon1;
      }
      &:nth-of-type(6) {
        top: 46px;
        right: 106px;
        transform: rotate(3deg);
        animation-name: balloon2;
      }
      &:nth-of-type(7) {
        top: 140px;
        right: -19px;
        transform: rotate(36deg) scale(1.3);
        animation-name: balloon3;
      }
      &:nth-of-type(8) {
        top: 315px;
        right: 49px;
        transform: rotate(10deg) scale(1.6);
        animation-name: balloon4;
      }
    }
    @include middle {
      display: none;
    }
    @include m {
      display: none;
    }
  }

  @keyframes balloon1 {
    from { transform: scale(1) translateY(-5px)}
    to {transform: scale(1.1) translateY(3px)}
  }
  @keyframes balloon2 {
    from { transform: scale(1) translateX(-2px) rotate(0deg)}
    to {transform: scale(1.1) translateX(3px) rotate(5deg)}
  }
  @keyframes balloon3 {
    from { transform: scale(1) translateY(0px)}
    to {transform: scale(1.2) translateY(3px)}
  }
  @keyframes balloon4 {
    from { transform: scale(1) rotate(0deg) translateY(-6px)}
    to {transform: scale(1.1) rotate(-5deg) translateY(2px)}
  }
