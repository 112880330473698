@mixin m {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin middle {
    @media (min-width: 768px) AND (max-width: 1215px) {
        @content;
    }
}