@import "mixins";

// Bootstrap

.container {
    max-width: 966px;
}

// Flickity

.flickity-prev-next-button.next {
    right: -40px;
    @include middle {
        display: none;
    }
    @include m {
        display: none;
    }
}
.flickity-prev-next-button.previous {
    left: -40px;
    @include middle {
        display: none;
    }
    @include m {
        display: none;
    }
}

.flickity-page-dots {
    bottom: 60px;
    .dot {
        width: 12px;
        height: 12px;
        &.is-selected {
            background: #48B1BF;
        }    
    }
    @include m {
        bottom: 80px;
    }
}